body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f2f2;
  background-size: 720px, auto;
  background-image: url('./components//images/background.png');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn::before {
  transition: all 0.15s linear 0s;
  content: '';
  position: absolute;
  bottom: -4px;
  right: 2px;
  width: 4px;
  height: 4px;
  background-color: #404040;
  transform: rotate(45deg);
  z-index: -1;
}

.btn::after {
  transition: all 0.15s linear 0s;
  content: '';
  position: absolute;
  top: 2px;
  left: -4px;
  width: 4px;
  height: 4px;
  background-color: #404040;
  transform: rotate(45deg);
  z-index: -1;
}

@font-face {
  font-family: 'Alarm Clock';
  src: url(./fonts//alarm-clock.ttf);
  font-weight: 300;
  font-style: normal;
}
